import { type HttpErrorResponse } from '@angular/common/http';
import { type Injector } from '@angular/core';
import { Router } from '@angular/router';
import { type PortalEntity, type UserMeEntity, type UserStorageEntity } from '@sds/api/administration/models';
import {
  AdministrationApiPortalService,
  AdministrationApiUserService,
  AdministrationApiUserStorageService
} from '@sds/api/administration/services';
import { type ProfitcenterField } from '@sds/api/ffuf-users/models';
import { FFUFUsersApiProfitcenterService } from '@sds/api/ffuf-users/services';
import { type LandingPageEntity } from '@sds/api/widget/models';
import { WidgetsLandingPageService } from '@sds/api/widget/services';
import { ConfigProperty, LocaleStore, PortalSettingsStore, UserStore, type ConfigService } from '@sds/shared';
import { Language, PassPortalFields, Theme } from '@sds/shared/enums';
import { handleJSON } from '@sds/shared/utils';
import { UserSetting, UserSettingsStore } from '@sds/user-settings';
import { browserTracingIntegration, init, replayIntegration, setUser } from '@sentry/angular';
import { catchError, filter, firstValueFrom, forkJoin, map, of, switchMap, tap, zip, type Observable } from 'rxjs';

/**
 * potentially can include server error codes but Jens wants to have an overview about them in Sentry
 * 500 Internal Server Error|502 Bad Gateway|503 Service Unavailable
 * https://stackoverflow.com/a/61653575/5790305
 */
const serverErrorsRegex = new RegExp('401 Unauthorized|403 Forbidden|404 Not Found', 'mi');

export function appInitializer(configService: ConfigService, injector: Injector): Promise<unknown> {
  const administrationApiUserService = injector.get(AdministrationApiUserService);
  const userStore = injector.get(UserStore);
  const localeStore = injector.get(LocaleStore);
  const router = injector.get(Router);
  const userStorageService = injector.get(AdministrationApiUserStorageService);
  const widgetsLandingPageService = injector.get(WidgetsLandingPageService);
  const userSettingsStore = injector.get(UserSettingsStore);
  const portalSettingsStore = injector.get(PortalSettingsStore);
  const portalService = injector.get(AdministrationApiPortalService);
  const passProfitcenterApiService = injector.get(FFUFUsersApiProfitcenterService);

  return firstValueFrom(
    zip([
      loadUser(administrationApiUserService, userStore, localeStore, router),
      loadUserSettings(userStorageService, widgetsLandingPageService, userSettingsStore)
    ]).pipe(
      map(([user]) => user as UserMeEntity),
      filter(user => !!user),
      switchMap(user => loadCurrentPortal(user.currentPortalId ?? '', portalService, portalSettingsStore)),
      switchMap(portal => loadPortalSettings(portal.ffufId ?? 0, passProfitcenterApiService, portalSettingsStore)),
      tap(() => {
        if (userStore.user()) {
          initSentry(configService, userStore.user()!);
        }
      })
    )
  );
}

function loadUser(
  administrationApiUserService: AdministrationApiUserService,
  userStore: UserStore,
  localeStore: LocaleStore,
  router: Router
): Observable<UserMeEntity | null> {
  return administrationApiUserService.getMeUserItem({ id: 'me' }).pipe(
    map((user: UserMeEntity) => {
      user.languageId = user.languageId!.replace('_', '-') as Language;
      return user;
    }),
    map(user => {
      if (user.languageId === Language.LO_US) {
        user.languageId = Language.LOL_US;
      }
      return user;
    }),
    tap(user => {
      userStore.setUser(user);
      localeStore.setLocale(user.languageId as Language);
    }),
    catchError(error => {
      router.navigate(['oauth-error'], { queryParams: { message: error?.message, status: error?.status } });
      return of(null);
    })
  );
}

function loadUserSettings(
  userStorageService: AdministrationApiUserStorageService,
  widgetsLandingPageService: WidgetsLandingPageService,
  userSettingsStore: UserSettingsStore
): Observable<UserStorageEntity[]> {
  return forkJoin([
    userStorageService.getUserStorageCollection().pipe(catchError(() => of([] as UserStorageEntity[]))),
    widgetsLandingPageService.getLandingPageCollection().pipe(catchError(() => of([] as LandingPageEntity[])))
  ]).pipe(
    map(([settings, landingPage]) => {
      if (landingPage.length) {
        const { id, pinboardId } = landingPage[0];
        if (pinboardId) {
          settings.push({
            id,
            name: 'landingPage',
            type: 'landingPage',
            values: [pinboardId]
          });
        }
      }
      return settings;
    }),
    tap(settings => {
      const temperatureItem: UserStorageEntity | undefined = settings.find(s => s.type === UserSetting.TemperatureUnit);
      const distanceItem: UserStorageEntity | undefined = settings.find(s => s.type === UserSetting.DistanceUnit);
      const landingPageItem: UserStorageEntity | undefined = settings.find(s => s.type === UserSetting.LandingPage);
      const landingPage: LandingPageEntity | null = landingPageItem
        ? {
            id: landingPageItem.id,
            pinboardId: landingPageItem.values[0] //the pinboard id is always the first value of the array.
          }
        : null;
      const themeItem: UserStorageEntity | undefined = settings.find(s => s.type === UserSetting.Theme);
      const theme =
        themeItem?.name && Object.values(Theme).includes(themeItem?.name as Theme)
          ? themeItem
          : userSettingsStore.themeEntity();
      const analysisFilters: UserStorageEntity | undefined =
        settings.find(s => s.type === UserSetting.AnalysisOverviewFilter) ?? userSettingsStore.analysisFiltersEntity();
      userSettingsStore.setLandingPageEntity(landingPage);
      userSettingsStore.setThemeEntity(theme);
      userSettingsStore.setAnalysisFiltersEntity(analysisFilters);

      if (temperatureItem) {
        userSettingsStore.setTemperatureUnitEntity(temperatureItem);
      }
      if (distanceItem) {
        userSettingsStore.setDistanceUnitEntity(distanceItem);
      }
    })
  );
}

function loadCurrentPortal(
  id: string,
  portalService: AdministrationApiPortalService,
  portalSettingsStore: PortalSettingsStore
): Observable<PortalEntity> {
  return portalService.getPortalItem({ id }).pipe(tap(portal => portalSettingsStore.setPortal(portal)));
}

function loadPortalSettings(
  ffufId: number,
  passProfitcenterApiService: FFUFUsersApiProfitcenterService,
  portalSettingsStore: PortalSettingsStore
): Observable<ProfitcenterField[]> {
  return passProfitcenterApiService
    .getFieldsByProfitcenterProfitcenterField({
      profitcenterId: ffufId
    })
    .pipe(
      map(profitCenterFields =>
        profitCenterFields.filter(
          field =>
            Object.values(PassPortalFields)
              .map(key => key.toLowerCase())
              .indexOf(field.fieldId.toLowerCase()) > -1
        )
      ),
      tap(fields => {
        const portalColors = fields.find(s => s.fieldId === PassPortalFields.PortalColors);
        if (portalColors) {
          const parsed = handleJSON<{ lightPrimaryColor: string; darkPrimaryColor: string }>(portalColors.fieldValue!);
          if (parsed && parsed.darkPrimaryColor && parsed.lightPrimaryColor) {
            portalSettingsStore.setThemeColors(portalColors.id!, {
              darkPrimaryColor: parsed.darkPrimaryColor,
              lightPrimaryColor: parsed.lightPrimaryColor
            });
          }
        }
        const automaticPlantAssignation = fields.find(f => f.fieldId === PassPortalFields.AutomaticPlantAssignation);
        if (automaticPlantAssignation) {
          portalSettingsStore.setAutomaticPlantAssignation(
            automaticPlantAssignation.id!,
            automaticPlantAssignation.fieldValue === 'true'
          );
        }
      })
    );
}

function initSentry(configService: ConfigService, user: UserMeEntity): void {
  const sentryUrl: string | null = configService.get(ConfigProperty.SentryDns);
  if (sentryUrl?.length) {
    setUser({ userId: user.id, email: user.primaryMail, username: user.displayName });
    init({
      dsn: sentryUrl,
      environment: window.location.hostname,
      release: `enerest-v4@${configService.get(ConfigProperty.VersionNumber) ?? '0.0.0'}`,
      replaysOnErrorSampleRate: 1,
      tracePropagationTargets: [window.location.origin, configService.get(ConfigProperty.BaseApiUrl)],
      tracesSampleRate: 1,
      ignoreTransactions: ['/print/report/:reportId/'],
      ignoreErrors: [serverErrorsRegex],
      integrations: [
        browserTracingIntegration(),
        replayIntegration({
          // Additional SDK configuration goes in here, for example:
          maskAllText: true,
          blockAllMedia: true
        })
      ],
      beforeSend: (event, hint) => {
        const response = hint.originalException as HttpErrorResponse;

        if (response && response.status && [401, 403, 404].includes(response.status)) {
          return null;
        }
        return event;
      }
    });
  }
}
