import { runInInjectionContext, type Injector } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { type UserMeEntity } from '@sds/api/administration/models';
import { AdministrationApiUserService } from '@sds/api/administration/services';
import { UserStore } from '@sds/shared';
import { SseService } from '@sds/shared/services';
import { filter, switchMap } from 'rxjs';

export function userObjectMercureInitializer(injector: Injector): void {
  const userStore = injector.get(UserStore);
  const sseService = injector.get(SseService);
  const apiService = injector.get(AdministrationApiUserService);

  runInInjectionContext(injector, () => {
    toObservable(userStore.user)
      .pipe(
        filter(user => !!user),
        switchMap((user: UserMeEntity) => sseService.getSseChannel(`/user/${user.id}`)),
        switchMap(() => apiService.getMeUserItem({ id: 'me' }))
      )
      .subscribe((user: UserMeEntity) => userStore.setUser(user));
  });
}
