import { type AuthConfig, type OAuthStorage } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/index.html',

  // set the scope for the permissions the client should request
  scope: 'openid profile',
  strictDiscoveryDocumentValidation: false,
  preserveRequestedRoute: true,
  customQueryParams: {
    responseMode: 'query'
  },
  skipSubjectCheck: true,
  requireHttps: false,
  responseType: 'code',
  sessionChecksEnabled: true,
  sessionCheckIntervall: 5000
};

/**
 * Sets storage to local storage.
 * Please note the oauth-error component clears local storage manually to try flow again.
 */
export function storageFactory(): OAuthStorage {
  return localStorage;
}
