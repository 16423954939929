import { inject, Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { type RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TitleFactory extends TitleStrategy {
  private readonly translateService = inject(TranslateService);
  private readonly titleService = inject(Title);
  constructor() {
    super();
  }
  updateTitle(snapshot: RouterStateSnapshot): void {
    const title = this.buildTitle(snapshot);
    this.titleService.setTitle(this.translateService.instant(`pageTitles.${title ? title : 'default'}`));
  }
}
