import { Injectable, inject } from '@angular/core';
import { ActionBarWidget } from '@sds/action-bar';
import { DailyAutarkyWidget, MonthlyAutarkyWidget, YearlyAutarkyWidget } from '@sds/autarky-charts';
import { AutharkyWidget } from '@sds/autharky';
import {
  DailyConsumedEnergyWidget,
  LifetimeConsumedEnergyWidget,
  MonthlyConsumedEnergyWidget,
  YearlyConsumedEnergyWidget
} from '@sds/consumed-energy';
import { CustomerLogoWidget } from '@sds/customer-logo';
import { UserDateTimeWidget } from '@sds/date-time-widget';
import { ECarWidget } from '@sds/e-car';
import { Enerest2GoWebviewWidget } from '@sds/enerest2go-webview';
import {
  DailyEnergyBalanceTableWidget,
  LifetimeEnergyBalanceTableWidget,
  MonthlyEnergyBalanceTableWidget,
  YearlyEnergyBalanceTableWidget
} from '@sds/energy-balance-table';
import {
  DailyEnergyYieldsWidget,
  EnergyYieldsWidget,
  MonthlyEnergyYieldsWidget,
  YearlyEnergyYieldsWidget
} from '@sds/energy-yields';
import { FleetErrorsHistoryWidget, PlantErrorsListWidget, PortfolioErrorsHistoryWidget } from '@sds/error-history';
import { EventLogWidget } from '@sds/eventlog';
import {
  FinancialEfficiencyDailyWidget,
  FinancialEfficiencyMonthlyWidget,
  FinancialEfficiencyYearlyWidget
} from '@sds/financial-efficiency';
import { FleetPlantsYieldsWidget } from '@sds/fleet-plants-yields';
import { FleetAssignedEntitiesWidget, FleetDetailWidget, FleetListWidget } from '@sds/fleets';
import { HealthStatusWidget } from '@sds/health-status';
import { ImageWidget } from '@sds/image-widget';
import { IncomeWidget } from '@sds/income';
import {
  LifetimeInverterRgmProductionComparisonListWidget,
  MonthLifetimeInverterRgmProductionComparisonListWidget
} from '@sds/inverter-rgm-production-comparison-list';
import {
  CompressedMonthlyInverterRgmProductionListWidget,
  MonthlyInverterRgmProductionListWidget,
  YearlyInverterRgmProductionListWidget
} from '@sds/inverter-rgm-production-list';
import { KpiDaySummaryWidget, KpiMonthSummaryWidget, KpiYearSummaryWidget } from '@sds/kpi';
import { LatestIncomingDataWidget } from '@sds/latest-incoming-data';
import { ManagedFleetsOverviewWidget } from '@sds/managed-fleets-overview';
import {
  BalanceLifetimeChartWidget,
  BalanceMonthChartWidget,
  BalanceYearChartWidget,
  ComponentCommunicationErrorPacChartWidget,
  ComponentCommunicationHeatmapWidget,
  DataTransferChartWidget,
  DataTransferErrorHeatmapWidget,
  DayBalanceChartWidget,
  ErrorDeviationChartWidget,
  ProductionDayChartWidget,
  ProductionFleetOverviewWidget,
  ProductionLifetimeChartWidget,
  ProductionMonthChartWidget,
  ProductionPortfolioOverviewWidget,
  ProductionYearChartWidget,
  StatusErrorChartWidget
} from '@sds/module-graphics';
import { PlantComponentConfigurationWidget } from '@sds/monitoring-plant-component-configuration';
import { PlantDisplayWidget } from '@sds/monitoring-plant-display';
import {
  ComponentComparisonWidget,
  PlantErrorAdditionalDataWidget,
  PlantErrorWidget
} from '@sds/monitoring-plant-error-details';
import { ErrorSettingsWidget } from '@sds/monitoring-plant-error-settings';
import { PlantsLocationsWidget } from '@sds/monitoring-plants-locations';
import { PlantsOverviewWidget } from '@sds/monitoring-plants-overview';
import { FleetsOverviewWidget } from '@sds/monitoring-portfolio-fleets-overview';
import {
  OwnConsumptionDayWidget,
  OwnConsumptionFeedInChartWidget,
  OwnConsumptionMonthWidget
} from '@sds/own-consumption-feed-in-chart';
import { PlantDetailWidgetsWidget } from '@sds/plant-detail';
import { PlantAssignedEntitiesWidget } from '@sds/plant-detail/plant-assigned-entities';
import { PlantDetailWidget } from '@sds/plant-detail/plant-detail-widget';
import { PlantSummaryWidget } from '@sds/plant-detail/plant-summary';
import { PlantPictureWidget } from '@sds/plant-picture';
import { PlantSetupWidget } from '@sds/plant-setup';
import {
  DailyYieldOverviewWidget,
  MonthlyYieldOverviewWidget,
  PlantYieldWidget,
  YearlyYieldOverviewWidget
} from '@sds/plant-yield';
import { TotalPlantsWidget } from '@sds/plants-list';
import { PortalsListWidget } from '@sds/portals';
import {
  DailySelfConsumptionWidget,
  MonthlySelfConsumptionWidget,
  YearlySelfConsumptionWidget
} from '@sds/self-consumption';
import {
  InsolationMonthlyTableWidget,
  InsolationYearlyTableWidget,
  IrradiationDailyTableWidget,
  SensorsDailyChartWidget,
  SensorsMonthlyChartWidget,
  SensorsYearlyChartWidget,
  TempModuleDailyTableWidget,
  TempModuleMonthlyTableWidget,
  TempModuleYearlyTableWidget
} from '@sds/sensors';
import { ClosedTasksWidget, OpenPlantCardsWidget, OpenTasksWidget } from '@sds/task-list';
import { TextWidget } from '@sds/text-widget';
import { TimelineEventsListWidget } from '@sds/timeline';
import { UserAssignedEntitiesWidget, UserDetailsWidget, UsersListWidget } from '@sds/users';
import { WeatherForecastWidget } from '@sds/weather-forecast';
import { type AsyncWidgetFactory, type WidgetFactory, WidgetGridsterStore } from '@sds/widget-gridster';
import { YearlyBalancedBatteryWithTargetWidget } from '@sds/yearly-balanced-battery-with-target';
import { LifetimeYieldsListWidget, YearlyYieldsListWidget } from '@sds/yields-list';
import { WidgetIds } from '../widgets/widget-ids';

export function widgetFactory(widgetFactoryInitializer: WidgetFactoryInitializer) {
  return () => widgetFactoryInitializer.initialize();
}

@Injectable({ providedIn: 'root' })
export class WidgetFactoryInitializer {
  private readonly widgetGridsterStore = inject(WidgetGridsterStore);

  initialize() {
    this.widgetGridsterStore.setFactories(this.registerFactories());
    this.widgetGridsterStore.setAsyncFactories(this.registerAsyncFactories());
  }

  private registerAsyncFactories(): Map<string, AsyncWidgetFactory> {
    const asyncFactories = new Map();
    asyncFactories.set(
      WidgetIds.CARBON_DIOXIDE,
      async () => (await import('../widgets/carbon-dioxide-factory')).createCarbonDioxideWidget
    );
    return asyncFactories;
  }

  private registerFactories(): Map<string, WidgetFactory> {
    const factories = new Map();
    factories.set(AutharkyWidget.id, AutharkyWidget.factory);
    factories.set(
      CompressedMonthlyInverterRgmProductionListWidget.id,
      CompressedMonthlyInverterRgmProductionListWidget.factory
    );
    factories.set(CustomerLogoWidget.id, CustomerLogoWidget.factory);
    factories.set(ECarWidget.id, ECarWidget.factory);
    factories.set(EnergyYieldsWidget.id, EnergyYieldsWidget.factory);
    factories.set(EventLogWidget.id, EventLogWidget.factory);
    factories.set(IncomeWidget.id, IncomeWidget.factory);
    factories.set(ImageWidget.id, ImageWidget.factory);
    factories.set(KpiDaySummaryWidget.id, KpiDaySummaryWidget.factory);
    factories.set(KpiMonthSummaryWidget.id, KpiMonthSummaryWidget.factory);
    factories.set(KpiYearSummaryWidget.id, KpiYearSummaryWidget.factory);
    factories.set(LatestIncomingDataWidget.id, LatestIncomingDataWidget.factory);
    factories.set(PlantDetailWidget.id, PlantDetailWidget.factory);
    factories.set(LifetimeConsumedEnergyWidget.id, LifetimeConsumedEnergyWidget.factory);
    factories.set(
      LifetimeInverterRgmProductionComparisonListWidget.id,
      LifetimeInverterRgmProductionComparisonListWidget.factory
    );
    factories.set(
      MonthLifetimeInverterRgmProductionComparisonListWidget.id,
      MonthLifetimeInverterRgmProductionComparisonListWidget.factory
    );
    factories.set(DailyAutarkyWidget.id, DailyAutarkyWidget.factory);
    factories.set(MonthlyAutarkyWidget.id, MonthlyAutarkyWidget.factory);
    factories.set(YearlyAutarkyWidget.id, YearlyAutarkyWidget.factory);
    factories.set(DailyConsumedEnergyWidget.id, DailyConsumedEnergyWidget.factory);
    factories.set(MonthlyConsumedEnergyWidget.id, MonthlyConsumedEnergyWidget.factory);
    factories.set(YearlyConsumedEnergyWidget.id, YearlyConsumedEnergyWidget.factory);
    factories.set(DailyEnergyBalanceTableWidget.id, DailyEnergyBalanceTableWidget.factory);
    factories.set(MonthlyEnergyBalanceTableWidget.id, MonthlyEnergyBalanceTableWidget.factory);
    factories.set(YearlyEnergyBalanceTableWidget.id, YearlyEnergyBalanceTableWidget.factory);
    factories.set(LifetimeEnergyBalanceTableWidget.id, LifetimeEnergyBalanceTableWidget.factory);
    factories.set(DailyEnergyYieldsWidget.id, DailyEnergyYieldsWidget.factory);
    factories.set(MonthlyEnergyYieldsWidget.id, MonthlyEnergyYieldsWidget.factory);
    factories.set(YearlyEnergyYieldsWidget.id, YearlyEnergyYieldsWidget.factory);
    factories.set(MonthlyInverterRgmProductionListWidget.id, MonthlyInverterRgmProductionListWidget.factory);
    factories.set(YearlyInverterRgmProductionListWidget.id, YearlyInverterRgmProductionListWidget.factory);
    factories.set(OwnConsumptionDayWidget.id, OwnConsumptionDayWidget.factory);
    factories.set(OwnConsumptionMonthWidget.id, OwnConsumptionMonthWidget.factory);
    factories.set(OwnConsumptionFeedInChartWidget.id, OwnConsumptionFeedInChartWidget.factory);
    factories.set(PlantSetupWidget.id, PlantSetupWidget.factory);
    factories.set(TextWidget.id, TextWidget.factory);
    factories.set(UserDateTimeWidget.id, UserDateTimeWidget.factory);
    factories.set(YearlyBalancedBatteryWithTargetWidget.id, YearlyBalancedBatteryWithTargetWidget.factory);
    factories.set(YearlyYieldsListWidget.id, YearlyYieldsListWidget.factory);
    factories.set(LifetimeYieldsListWidget.id, LifetimeYieldsListWidget.factory);
    factories.set(ClosedTasksWidget.id, ClosedTasksWidget.factory);
    factories.set(OpenPlantCardsWidget.id, OpenPlantCardsWidget.factory);
    factories.set(OpenTasksWidget.id, OpenTasksWidget.factory);
    factories.set(ActionBarWidget.id, ActionBarWidget.factory);
    factories.set(PlantDetailWidgetsWidget.id, PlantDetailWidgetsWidget.factory);
    factories.set(PlantAssignedEntitiesWidget.id, PlantAssignedEntitiesWidget.factory);
    factories.set(FleetListWidget.id, FleetListWidget.factory);
    factories.set(FleetAssignedEntitiesWidget.id, FleetAssignedEntitiesWidget.factory);
    factories.set(FleetDetailWidget.id, FleetDetailWidget.factory);
    factories.set(PlantPictureWidget.id, PlantPictureWidget.factory);
    factories.set(TotalPlantsWidget.id, TotalPlantsWidget.factory);
    factories.set(PortalsListWidget.id, PortalsListWidget.factory);
    factories.set(UsersListWidget.id, UsersListWidget.factory);
    factories.set(UserDetailsWidget.id, UserDetailsWidget.factory);
    factories.set(UserAssignedEntitiesWidget.id, UserAssignedEntitiesWidget.factory);
    factories.set(TimelineEventsListWidget.id, TimelineEventsListWidget.factory);
    factories.set(PlantSummaryWidget.id, PlantSummaryWidget.factory);
    factories.set(BalanceLifetimeChartWidget.id, BalanceLifetimeChartWidget.factory);
    factories.set(ComponentCommunicationHeatmapWidget.id, ComponentCommunicationHeatmapWidget.factory);
    factories.set(ComponentCommunicationErrorPacChartWidget.id, ComponentCommunicationErrorPacChartWidget.factory);
    factories.set(BalanceYearChartWidget.id, BalanceYearChartWidget.factory);
    factories.set(BalanceMonthChartWidget.id, BalanceMonthChartWidget.factory);
    factories.set(DataTransferChartWidget.id, DataTransferChartWidget.factory);
    factories.set(DataTransferErrorHeatmapWidget.id, DataTransferErrorHeatmapWidget.factory);
    factories.set(DayBalanceChartWidget.id, DayBalanceChartWidget.factory);
    factories.set(ErrorDeviationChartWidget.id, ErrorDeviationChartWidget.factory);
    factories.set(WeatherForecastWidget.id, WeatherForecastWidget.factory);
    factories.set(HealthStatusWidget.id, HealthStatusWidget.factory);
    factories.set(ComponentComparisonWidget.id, ComponentComparisonWidget.factory);
    factories.set(ErrorSettingsWidget.id, ErrorSettingsWidget.factory);
    factories.set(FleetErrorsHistoryWidget.id, FleetErrorsHistoryWidget.factory);
    factories.set(PlantErrorAdditionalDataWidget.id, PlantErrorAdditionalDataWidget.factory);
    factories.set(PlantErrorWidget.id, PlantErrorWidget.factory);
    factories.set(FleetPlantsYieldsWidget.id, FleetPlantsYieldsWidget.factory);
    factories.set(FleetsOverviewWidget.id, FleetsOverviewWidget.factory);
    factories.set(ProductionYearChartWidget.id, ProductionYearChartWidget.factory);
    factories.set(ProductionPortfolioOverviewWidget.id, ProductionPortfolioOverviewWidget.factory);
    factories.set(ProductionDayChartWidget.id, ProductionDayChartWidget.factory);
    factories.set(ProductionFleetOverviewWidget.id, ProductionFleetOverviewWidget.factory);
    factories.set(ProductionLifetimeChartWidget.id, ProductionLifetimeChartWidget.factory);
    factories.set(ProductionMonthChartWidget.id, ProductionMonthChartWidget.factory);
    factories.set(PlantComponentConfigurationWidget.id, PlantComponentConfigurationWidget.factory);
    factories.set(PlantDisplayWidget.id, PlantDisplayWidget.factory);
    factories.set(PlantErrorsListWidget.id, PlantErrorsListWidget.factory);
    factories.set(PlantYieldWidget.id, PlantYieldWidget.factory);
    factories.set(PlantsLocationsWidget.id, PlantsLocationsWidget.factory);
    factories.set(PlantsOverviewWidget.id, PlantsOverviewWidget.factory);
    factories.set(PortfolioErrorsHistoryWidget.id, PortfolioErrorsHistoryWidget.factory);
    factories.set(StatusErrorChartWidget.id, StatusErrorChartWidget.factory);
    factories.set(ManagedFleetsOverviewWidget.id, ManagedFleetsOverviewWidget.factory);
    factories.set(YearlySelfConsumptionWidget.id, YearlySelfConsumptionWidget.factory);
    factories.set(MonthlySelfConsumptionWidget.id, MonthlySelfConsumptionWidget.factory);
    factories.set(DailySelfConsumptionWidget.id, DailySelfConsumptionWidget.factory);
    factories.set(FinancialEfficiencyMonthlyWidget.id, FinancialEfficiencyMonthlyWidget.factory);
    factories.set(FinancialEfficiencyYearlyWidget.id, FinancialEfficiencyYearlyWidget.factory);
    factories.set(FinancialEfficiencyDailyWidget.id, FinancialEfficiencyDailyWidget.factory);
    factories.set(Enerest2GoWebviewWidget.id, Enerest2GoWebviewWidget.factory);
    factories.set(SensorsDailyChartWidget.id, SensorsDailyChartWidget.factory);
    factories.set(SensorsMonthlyChartWidget.id, SensorsMonthlyChartWidget.factory);
    factories.set(SensorsYearlyChartWidget.id, SensorsYearlyChartWidget.factory);
    factories.set(InsolationYearlyTableWidget.id, InsolationYearlyTableWidget.factory);
    factories.set(TempModuleYearlyTableWidget.id, TempModuleYearlyTableWidget.factory);
    factories.set(InsolationMonthlyTableWidget.id, InsolationMonthlyTableWidget.factory);
    factories.set(TempModuleMonthlyTableWidget.id, TempModuleMonthlyTableWidget.factory);
    factories.set(IrradiationDailyTableWidget.id, IrradiationDailyTableWidget.factory);
    factories.set(TempModuleDailyTableWidget.id, TempModuleDailyTableWidget.factory);
    factories.set(DailyYieldOverviewWidget.id, DailyYieldOverviewWidget.factory);
    factories.set(MonthlyYieldOverviewWidget.id, MonthlyYieldOverviewWidget.factory);
    factories.set(YearlyYieldOverviewWidget.id, YearlyYieldOverviewWidget.factory);
    return factories;
  }
}
