import { BreakpointObserver } from '@angular/cdk/layout';
import { HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import {
  type ApplicationConfig,
  ErrorHandler,
  Injector,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideExperimentalCheckNoChangesForDebug,
  provideZoneChangeDetection
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {
  Router,
  TitleStrategy,
  UrlSerializer,
  provideRouter,
  withComponentInputBinding,
  withViewTransitions
} from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, provideTranslateService } from '@ngx-translate/core';
import { loadingBarInterceptor } from '@sds/loading-bar';
import { ConfigService, DeviceStore, ENVIRONMENT, Environment } from '@sds/shared';
import { provideMaterial } from '@sds/shared/material';
import { DateFnsDateAdapter } from '@sds/shared/utils';
import { TraceService, createErrorHandler } from '@sentry/angular';
import { OAuthService, OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc';
import { provideLottieOptions } from 'ngx-lottie';
import { environment } from '../environments/environment';
import { apiServiceRootUrlsInitializer } from './initializers/api-service-root-urls.initializer';
import { appInitializer } from './initializers/app.initializer';
import { deviceMediaObserverInitializer } from './initializers/device-media-observer.initializer';
import { tokensInitializer } from './initializers/tokens.initializer';
import { userObjectMercureInitializer } from './initializers/user-object-mercure.initializer';
import { WidgetFactoryInitializer, widgetFactory } from './initializers/widget-factory.initializer';
import { httpInterceptorProviders } from './interceptors';
import { storageFactory } from './oauth2.config';
import { TitleFactory } from './page-title.factory';
import { routes } from './routes';
import { translateLoaderFactory } from './translate-loader.factory';
import { CustomUrlSerializer } from './url-serializer';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideExperimentalCheckNoChangesForDebug({ interval: 1000 }),
    provideMaterial(),
    provideAnimationsAsync(),
    provideRouter(routes, withViewTransitions(), withComponentInputBinding()),
    provideOAuthClient(),
    provideLottieOptions({
      player: () => import('lottie-web')
    }),
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([loadingBarInterceptor])),
    provideTranslateService({
      loader: { provide: TranslateLoader, useFactory: translateLoaderFactory, deps: [HttpClient, ConfigService] }
    }),
    importProvidersFrom(
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        registrationStrategy: 'registerWhenStable:10000'
      })
    ),
    { provide: OAuthStorage, useFactory: storageFactory },
    provideAppInitializer(
      async (
        configService = inject(ConfigService),
        injector = inject(Injector),
        oauthService = inject(OAuthService)
      ) => {
        await configService.loadConfigurationData(environment.e2e);
        await apiServiceRootUrlsInitializer(configService, injector);
        await tokensInitializer(configService, injector);
        if (oauthService.hasValidAccessToken()) {
          await appInitializer(configService, injector);
          userObjectMercureInitializer(injector);
        }
      }
    ),
    provideAppInitializer(() => deviceMediaObserverInitializer(inject(DeviceStore), inject(BreakpointObserver))()),
    provideAppInitializer(() => widgetFactory(inject(WidgetFactoryInitializer))()),
    provideAppInitializer(() => {
      inject(TraceService);
    }),
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: false
      })
    },
    {
      provide: TraceService,
      deps: [Router]
    },
    {
      provide: ENVIRONMENT,
      useValue: environment.production ? Environment.PROD : Environment.DEV
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: TitleStrategy, useClass: TitleFactory },
    { provide: DateAdapter, useClass: DateFnsDateAdapter }
  ]
};
