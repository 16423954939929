import { inject, type Injector, runInInjectionContext } from '@angular/core';
import {
  AdministrationApiConfiguration,
  BillingApiConfiguration,
  DatasourceApiConfiguration,
  ErrorDetectionApiConfiguration,
  FFuFAutomationApiConfiguration,
  FFuFKeycloakUsersApiConfiguration,
  FFuFLogFilesApiConfiguration,
  FFuFQuotaStatisticsApiConfiguration,
  FilestorageApiConfiguration,
  IntervalImportApiConfiguration,
  ReportingApiConfiguration,
  SolarLogAdapterApiConfiguration,
  SolarLogInterchangeActivePowerService,
  SolarLogInterchangeComponentConfigurationService,
  SolarLogInterchangeComponentDetectionService,
  SolarLogInterchangeFirmwareUpdateService,
  SolarLogInterchangeInterconnectorService,
  SolarLogInterchangePlantParametersService,
  SolarLogInterchangeReactivePowerService,
  SolarLogInterchangeTelecontrolConfigurationService,
  TimelineApiConfiguration,
  VisualisationApiConfiguration,
  WeatherForecastApiConfiguration,
  WidgetsApiConfiguration
} from '@sds/api';
import { NotificationService } from '@sds/api/ffuf-notifications/services';
import {
  FFUFUsersApiDeviceService,
  FFUFUsersApiProfitcenterService,
  FFUFUsersApiUserService
} from '@sds/api/ffuf-users/services';
import { ConfigProperty, type ConfigService } from '@sds/shared';

export async function apiServiceRootUrlsInitializer(configService: ConfigService, injector: Injector): Promise<void> {
  runInInjectionContext(injector, () => {
    inject(AdministrationApiConfiguration).rootUrl = configService.get(ConfigProperty.AdministrationApi);
    inject(BillingApiConfiguration).rootUrl = configService.get(ConfigProperty.BillingApi);
    inject(DatasourceApiConfiguration).rootUrl = configService.get(ConfigProperty.DatasourceApi);
    inject(ErrorDetectionApiConfiguration).rootUrl = configService.get(ConfigProperty.ErrorDetectionApi);
    inject(ReportingApiConfiguration).rootUrl = configService.get(ConfigProperty.ReportingApi);
    inject(TimelineApiConfiguration).rootUrl = configService.get(ConfigProperty.TimelineApi);
    inject(VisualisationApiConfiguration).rootUrl = configService.get(ConfigProperty.VisualisationApi);
    inject(WidgetsApiConfiguration).rootUrl = configService.get(ConfigProperty.WidgetsApi);
    inject(NotificationService).rootUrl = configService.get(ConfigProperty.NotificationsApi);
    inject(FFUFUsersApiUserService).rootUrl = configService.get(ConfigProperty.UserApiUrl);
    inject(FFUFUsersApiDeviceService).rootUrl = configService.get(ConfigProperty.UserApiUrl);
    inject(FFUFUsersApiProfitcenterService).rootUrl = configService.get(ConfigProperty.UserApiUrl);
    inject(FFuFKeycloakUsersApiConfiguration).rootUrl = configService.get(ConfigProperty.PasswordApiUrl);
    inject(WeatherForecastApiConfiguration).rootUrl = configService.get(ConfigProperty.WeatherDataApi);
    inject(SolarLogAdapterApiConfiguration).rootUrl = configService.get(ConfigProperty.SolarLogAdapterApi);
    inject(FFuFLogFilesApiConfiguration).rootUrl = configService.get(ConfigProperty.LogfileApiUrl);
    inject(FFuFQuotaStatisticsApiConfiguration).rootUrl = configService.get(ConfigProperty.QuotaApiUrl);
    inject(FFuFAutomationApiConfiguration).rootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    inject(FFuFAutomationApiConfiguration).logsRootUrl = configService.get(ConfigProperty.AutomationApiUrl);
    inject(IntervalImportApiConfiguration).rootUrl = configService.get(ConfigProperty.IntervalImport);
    inject(FilestorageApiConfiguration).rootUrl = configService.get(ConfigProperty.FilestorageApi);
    inject(SolarLogInterchangeComponentDetectionService).rootUrl = configService.get(
      ConfigProperty.SolarLogInterchangeApi
    );
    inject(SolarLogInterchangeFirmwareUpdateService).rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
    inject(SolarLogInterchangeInterconnectorService).rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
    inject(SolarLogInterchangeComponentConfigurationService).rootUrl = configService.get(
      ConfigProperty.SolarLogInterchangeApi
    );
    inject(SolarLogInterchangeActivePowerService).rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
    inject(SolarLogInterchangeReactivePowerService).rootUrl = configService.get(ConfigProperty.SolarLogInterchangeApi);
    inject(SolarLogInterchangePlantParametersService).rootUrl = configService.get(
      ConfigProperty.SolarLogInterchangeApi
    );
    inject(SolarLogInterchangeTelecontrolConfigurationService).rootUrl = configService.get(
      ConfigProperty.SolarLogInterchangeApi
    );
  });
}
