import { type Injector } from '@angular/core';
import { ConfigProperty, type ConfigService } from '@sds/shared';
import { OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { authConfig } from '../oauth2.config';

export async function tokensInitializer(configService: ConfigService, injector: Injector): Promise<void> {
  const oauthService = injector.get(OAuthService);
  const oAuthStorage = injector.get(OAuthStorage);

  oauthService.configure({
    ...authConfig,
    clientId: configService.get(ConfigProperty.OauthClientId),
    issuer: configService.get(ConfigProperty.Issuer),
    tokenEndpoint: configService.get(ConfigProperty.TokenEndpoint),
    userinfoEndpoint: configService.get(ConfigProperty.UserApiUrl),
    loginUrl: configService.get(ConfigProperty.LoginUrl),
    logoutUrl: configService.get(ConfigProperty.LogoutUrl)
  });

  /**
   * This code is needed for BE printing of reports. BE gets access token and pass it in url params to us
   */
  if (window.location.search.includes('token') && !oAuthStorage.getItem('access_token')) {
    const accessToken = window.location.search.substring(window.location.search.indexOf('token')).replace('token=', '');
    oAuthStorage.setItem('access_token', accessToken);
  }

  /**
   * This method internally only parse Authorization Code and sends POST to token endpoint to get access token.
   * The responsibility of invoking code flow is in `canAccessProtectedRoutesGuard` that invokes code flow only for private routes.
   */
  await oauthService.loadDiscoveryDocumentAndTryLogin();
  if (oauthService.hasValidAccessToken()) {
    oauthService.setupAutomaticSilentRefresh();
  }
}
